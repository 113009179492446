<div class="main-channel">
    <!-- <section class="main-banner-wrapper">
        <div class="banner-img-wrapper">
            <div class="left-banner-color" [style.background-color]="appBGColor"></div>
        </div>
        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
        <div class="container info-wrapper" [style.margin-top.px]="logoSize-50">
        </div>
    </section> -->
    <!-- <app-video-player [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                </app-video-player> -->
    <div class="manual-network-wrapper" *ngIf="isManual">
        <section class="main-description-player-wrapper" [ngStyle]="{'padding-top': logoHeight ? ((logoHeight) + 'px') : ''}">
            <div class="container">
                <div class="d-flex align-items-center col-12">
                    <div class="col-12">
                        <div class="video-player-wrapper" style="background-color: #000;height: 618px;">
                            <ng-container>
                                <!-- isM3u8 - {{isM3u8}} currentVedioIsPaid - {{currentVedioIsPaid}} isSubscriptionAvailable-{{isSubscriptionAvailable}} loginRequired-{{loginRequired}} -->
                                <app-video-player style="width: 1100px;" *ngIf="isM3u8 && !currentVedioIsPaid && !isSubscriptionAvailable && !videoIsStopped && !loginRequired" [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type: videoJsPlayerType }]}">
                                </app-video-player>
                                <app-video-player style="width: 1100px;" *ngIf="currentVedioIsPaid && isSubscriptionAvailable || loginRequired" class="isPaidType" [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: '',type: '' }]}">
                                </app-video-player>
                                <app-video-player style="width: 1100px;" class="videoIsStopped" *ngIf="!currentVedioIsPaid && !isSubscriptionAvailable && videoIsStopped && !loginRequired"  [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: '',type: '' }]}">
                                </app-video-player>
                                <iframe *ngIf="!isM3u8 && !currentVedioIsPaid && !isSubscriptionAvailable && !loginRequired && !videoIsStopped" width="1165" height="655" [src]="videoUrl" frameborder="0" controls="0" allowfullscreen></iframe>
                                <span class="player-logo" *ngIf="showLogo" [ngClass]="[logoOnRight ? 'logoPositionRight' : 'logoPositionLeft']">
                                    <img style="opacity: 0;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                </span>
                                <!-- <div class="disable-div-playPause"></div>
                                <div class="disable-div-progress"></div> -->
                            </ng-container>
                            <div class="paid-info" *ngIf="currentVedioIsPaid">
                                <p class="vedio-name mb-20">{{channelName}}</p>
                                <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                                    class="watch-now mb-20">Get Access Now</button>
                                <button *ngIf="isOneTimePurchase && itemPrice"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                                    class="watch-now mb-20" style="margin-left: 10px;">Pay Per View ${{itemPrice}}</button>
                                <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                                        (click)="signInOnMedia()">sign up
                                    </span>to continue</p>
                            </div>
            
                            <div class="paid-info" *ngIf="loginRequired">
                                <p class="vedio-name mb-20">Please create a free account to watch this channel</p>
                                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                    (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
                                <p class="vedio-name">or <span style="cursor: pointer;text-decoration: underline;" (click)="signInOnMedia()">Sign in </span>to
                                    continue</p>
                            </div>
                        </div>
                        <div class="video-details">
                            <h1 class="video-name">{{currentPlayingItemName}}</h1>
                            <p class="video-description">{{itemDescription}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- <div class="m3u-network-wrapper" *ngIf="!isManual">
        <div class="nb-spinner" *ngIf="showSpinner"></div>
        <div class="main-description-player-wrapper overflow-x-wrapper" [ngClass]="{'viewall-active': viewAllSelected}">
            <div class="container">
                <div class="m3u-tabs" *ngIf="showm3uContainer">
                    <ul>
                        <li [ngClass]="{active: m3uType === 'M3U_CHANNEL'}" (click)="selectM3uType('M3U_CHANNEL')">Channels</li>
                        <li [ngClass]="{active: m3uType === 'M3U_MOVIES'}" (click)="selectM3uType('M3U_MOVIES')">Movies</li>
                        <li [ngClass]="{active: m3uType === 'M3U_SERIES'}" (click)="selectM3uType('M3U_SERIES')">Series</li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="showm3uContainer && m3uType !== 'M3U_CHANNEL'">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="d-flex align-items-center">
                            <p-dropdown  [options]="m3uCategory" *ngIf="selectedM3uCategoryNameFromDropdown" (onChange)="selectCategory($event)" [(ngModel)]="selectedM3uCategory" optionLabel="categoryName" [filter]="true" filterBy="categoryName" [showClear]="true" placeholder="Select a Category">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2" *ngIf="selectedM3uCategory">
                                        <div>{{ selectedM3uCategory.categoryName }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ item.categoryName }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="app-search app-search-big mr-20 align-bar">
                                <input type="text" placeholder="Search" class="form-control " (keyup)="searchData($event)" #searchInput>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="m3u-data" *ngFor="let item of m3uData">
                            <div class=" title-wrapper d-flex justify-content-between align-items-center">
                                <h1 class="cagtagory-name">{{item.Category}}</h1>
                                <p class="viewAll" *ngIf="!showViewAll && item.Playlist.length>4" (click)="getM3uDataByCatagory(item.Category)">View all</p>
                                <p class="viewAll" *ngIf="showViewAll && viewAllSelected" (click)="goBackView()">Go Back</p>
                            </div>
                            <div class="playlistdata-wrapper" [ngClass]="{'wrap-data': showViewAll}">
                                <div class="playlistdata" *ngFor="let data of item.Playlist">
                                    <div class="d-flex flex-column">
                                        <div class="card" (click)="goToView(data)">
                                            <img class="item-logo" style="opacity:0" onload="style.opacity=1" [src]="data.tvgLogo" alt="" (error)="handleMissingImage($event)">
                                        </div>
                                        <p class="data-name">{{data.channelName}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" *ngIf="showNotFound">
                        <p>No result found</p>
                    </div>
                    <div class="no-data" style="text-align: center;line-height: 1.4;" *ngIf="showErrorMessage">
                        <p style="color: red;">Oops! This URL is invalid. Please use correct URL and try again.</p>
                    </div>
                </div>
            </div>
            <div *ngIf="m3uType === 'M3U_CHANNEL'">
                <app-m3uchannel [m3uData]="data" (scrolledToBottom)="onScrollEnd()"></app-m3uchannel>
            </div>
        </div>
    </div>
    <div class="pagintion m3u-pagination" *ngIf="showViewAll && viewAllSelected">
        <p-paginator class="custom_paginator" [rows]="rowCount" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} - {last} of {{totalElements}}"
            [rowsPerPageOptions]="[25,50,100,200]" (onPageChange)="loadMediaItem($event)"
            [totalRecords]="totalElements" pageLinkSize="3"></p-paginator>
    </div> -->
</div>
<p-dialog *ngIf="paymentModal" [closable]="false" header="" [(visible)]="paymentModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <div class="paid-info" *ngIf="currentVedioIsPaid">
            <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                class="watch-now mb-20">Get Access Now</button>
            <button *ngIf="isOneTimePurchase"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                class="watch-now mb-20" style="margin-left: 10px;">Pay Per View ${{itemPrice}}</button>
            <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                    (click)="signInOnMedia()">sign up
                </span>to continue</p>
        </div>

        <div class="paid-info" *ngIf="loginRequired">
            <p class="vedio-name mb-20">Please create a free account to watch this video</p>
            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
            <p class="vedio-name">or <span style="cursor: pointer;" (click)="signInOnMedia()">sign in </span>to
                continue</p>
        </div>
    </div>
    <p-footer class="signout-footer">
        <!-- <button type="button" class="btn btn-white" style="margin: 0 10px;" (click)="logOut()">Yes</button> -->
        <button type="button" class="btn btn-white" (click)="paymentModal= false">Cancel</button>
    </p-footer>
</p-dialog>