// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authService: 'authentication-service/api/v1/',
  adminService: 'admin-service/api/v1/',
  webService: 'web-service/api/v1/',
  image_service: 'image-service/api/v1/',

  // basePath : `${window.location.protocol}//${window.location.hostname}/`,
  // baseUrl : `${window.location.protocol}//${window.location.hostname}/`
  basePath: 'https://oodles.nowcast.cc/',
  baseUrl: 'https://oodles.nowcast.cc/',
  // antMediaServerUrl: "https://mediaprod1.nowcast.cc:5443",
  // imageService: 'https://dashboard.nowcast.cc/image-service/api/v1/upload/load/',
  // dashboard: 'https://dashboard.nowcast.cc/',
  antMediaServerUrl: 'https://media.nowcast.cc:5443',
  imageService:
    'https://dev.nowcast.cc/image-service/api/v1/upload/load/',
  dashboard: 'https://dev.nowcast.cc/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
