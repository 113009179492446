<div class="main-channel" *ngIf="showUI">
    <!-- <section class="main-banner-wrapper">
        <div class="banner-img-wrapper">
            <div class="left-banner-color" [style.background-color]="appBGColor"></div>
        </div>
        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
        <div class="container info-wrapper" [style.margin-top.px]="logoSize-50">
        </div>
    </section> -->
    <div class="manual-network-wrapper" *ngIf="isManual">
        <section class="main-description-player-wrapper" [ngStyle]="{'margin-top': logoHeight ? ((logoHeight) + 'px') : ''}">
            <div class="containers" style="margin: 0 20px;">
                <div class="d-flex align-items-center col-12">
                    <div class="detail-card">
                        <div class="d-flex flex-column channel-detail-wrapper" *ngIf="currentSelectedChannelName">
                            <img style="opacity: 0;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                            <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName}}</p>
                            <p class="timing" *ngIf="currentStartItemTime">{{ currentStartItemTime | date: 'hh:mm a'}} <span *ngIf="currentStartItemTime">-</span> {{currentEndItemTime | date: 'hh:mm a'}}</p>
                            <p class="description">{{itemDescription ? itemDescription : ''}}</p>
                            <p class="description" style="opacity: 0;line-height: 0;margin: 0;">Let’s Talk Geography: 55 Geographical Terms (Kids Need to Know) takes students around the world to discover all the different</p>
                            <!-- <p class="channel-name" >Item name</p>
                            <p class="timing">10:00AM - 11:19AM</p>
                            <p class="description">description description description description</p> -->
                        </div>
                    </div>
                    <div class="">
                        <div class="video-player-wrapper" style="height: 315px; background-color: #000;">
                            <ng-container>
                                <!-- <app-video-player [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                </app-video-player> -->
                                <app-video-player style="width: 560px;" *ngIf="currentVedioIsPaid && isSubscriptionAvailable || loginRequired" class="isPaidType" [poster]="poster" [pauseTime]="pauseTime" (playedAction)="updateVedioPlay($event)"
                                    (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: '',type: '' }]}">
                                </app-video-player>
                                <iframe width="560" height="315" *ngIf="!currentVedioIsPaid && !isSubscriptionAvailable && !loginRequired" [src]="videoUrl" frameborder="0" controls="0" allowfullscreen></iframe>
                                <!-- <iframe width="560" height="315" sandbox="allow-scripts allow-same-origin" src="https://media.nowcast.cc:5443/LiveApp/play.html?name={{streamId ? streamId : ''}}" frameborder="0" allowfullscreen></iframe> -->
                                <span class="player-logo" *ngIf="logoOnPlayer" [ngClass]="[logoOnRight ? 'logoPositionRight' : 'logoPositionLeft']">
                                    <img style="opacity: 0;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                </span>
                                <div class="disable-div-playPause"></div>
                                <div class="disable-div-progress"></div>
                            </ng-container>
                            <div class="paid-info" *ngIf="currentVedioIsPaid">
                                <!-- <p class="vedio-name mb-20">{{currentVedioTitle}}</p> -->
                                <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                                    class="watch-now mb-20">Get Access Now</button>
                                <button *ngIf="isOneTimePurchase && itemPrice"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                                    class="watch-now mb-20" style="margin-left: 10px;">Pay Per View ${{itemPrice}}</button>
                                <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                                        (click)="signInOnMedia()">sign up
                                    </span>to continue</p>
                            </div>
                            <div class="paid-info" *ngIf="loginRequired">
                                <p class="vedio-name mb-20">Please create a free account to watch this channel</p>
                                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                    (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
                                <p class="vedio-name">or <span style="cursor: pointer;text-decoration: underline;" (click)="signInOnMedia()">Sign in </span>to
                                    continue</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="main-catagory">
            <div class="catagory-wrapper">
                <ul>
                    <li  *ngFor="let item of categoryData">{{item.categoryName}}</li>
                </ul>
            </div>
        </section>
        <section class="main-playlist-timeline">
            <div>
                <div class="d-flex flex-column">
                    <div class=" d-flex time-wrapper" #scrollTwo>
                        <p class="day-time">{{currentDay}}, {{currentDate | date:'hh:mm a'}}</p>
                        <div class="d-flex all-time" *ngFor="let item of allDayDefault">
                            <p>{{item.start | date: 'hh:mm a'}}</p>
                            <!-- <p>12:00 AM</p>
                            <p>12:30 AM</p>
                            <p>01:00 AM</p> -->
                        </div>
                        <div class="ruler-wrapper" #scrollthree>
                            <span class="ruler-horizontal" [style.backgroundColor]="primaryColor ? primaryColor : '#fff'" [ngStyle]="{'width': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 385) + 'px') : '0'}"></span>
                            <span class="triangle-down" [style.borderTop]="primaryColor ? ('19px solid ' + primaryColor) : '#fff'" [title]="currentDate | date:'hh:mm:ss a'" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 360) + 'px') : '0'}"></span>
                            <!-- <span class="ruler-verticle" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 198) + 'px') : '0'}"></span> -->
                        </div>
                    </div>
                    <div class="playlist-wrapper" (scroll)="updateScroll()" #scrollOne>
                        <div class="d-flex">
                            <div>
                                <div class="channelDataSaprate" *ngFor="let item of channelData" >
                                    <!-- <p class="catagory-title">{{item.Category}}</p> -->
                                    <div class="channel-wrapper d-flex" *ngFor="let data of item.Playlist" (click)="selectChannel(data)"  [ngClass]="{'activeChannel': data.isSelected}" >
                                        <p class="d-flex align-items-center">
                                            <span class="d-flex" style="width: 35%;">
                                                <!-- {{data.channelNumber}} -->
                                                <img [src]="data.logoPath"
                                                    style="width:auto;height:55px;margin-left:15px;object-fit: contain;max-width: 90px;margin-right: 15px;" alt="">
                                            </span>
                                            <span class="d-flex justify-content-center" style="font-size: 18px;margin-right: 10px;min-width: 55px;">
                                                {{data.channelNumber}}
                                            </span>
                                            <span class="d-flex justify-content-center" style="font-size: 18px;">
                                                {{data.title}}
                                            </span>
                                            <!-- <span class="d-flex justify-content-center" style="width: 60%;">
                                                <p>{{data.title}}</p>
                                            </span> -->
                                        </p>
                                        <div class="all-playlist-wrapper d-flex" *ngFor="let item of data.schedulingChannelInfoDTO">
                                            <div class="playlist-data" [ngStyle]="{'width': item.duration ? ((item.duration * 0.13888889) + 'px') : '0'}" [title]="item.name"><span>{{item.name}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="no-data" *ngIf="channelData.length === 0">No data exist</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    </div>

    <!-- <div class="m3u-network-wrapper" *ngIf="!isManual">
        <div class="nb-spinner" *ngIf="showSpinner"></div>
        <div class="main-description-player-wrapper overflow-x-wrapper">
            <div class="container">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="d-flex align-items-center">
                            <p-dropdown  [options]="m3uCategory" (onChange)="selectCategory($event)" [(ngModel)]="selectedM3uCategory" optionLabel="categoryName" [filter]="true" filterBy="categoryName" [showClear]="true" placeholder="Select a Category">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2" *ngIf="selectedM3uCategory">
                                        <div>{{ selectedM3uCategory.categoryName }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ item.categoryName }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="app-search app-search-big mr-20 align-bar">
                                <input type="text" placeholder="Search" class="form-control " (keyup)="searchData($event)" #searchInput>
                            </div>
                        </div>
                    </div>
                    <div class="m3u-data" *ngFor="let item of m3uData">
                        <div class=" title-wrapper d-flex justify-content-between align-items-center">
                            <h1 class="cagtagory-name">{{item.Category}}</h1>
                            <p class="viewAll" *ngIf="!showViewAll" (click)="getM3uDataByCatagory(item.Category)">View all</p>
                            <p class="viewAll" *ngIf="showViewAll" (click)="goBackView()">Go Back</p>
                        </div>
                        <div class="playlistdata-wrapper" [ngClass]="{'wrap-data': showViewAll}">
                            <div class="playlistdata" *ngFor="let data of item.Playlist">
                                <div class="d-flex flex-column">
                                    <div class="card" (click)="goToView(data)">
                                        <img class="item-logo" style="opacity:0" onload="style.opacity=1" [src]="data.tvgLogo" alt="" (error)="handleMissingImage($event)">
                                    </div>
                                    <p class="data-name">{{data.channelName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" *ngIf="showNotFound">
                        <p>No result found</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="m3u-network-wrapper" *ngIf="!isManual">
        <div class="nb-spinner" *ngIf="showSpinner"></div>
        <div class="main-description-player-wrapper overflow-x-wrapper" [ngStyle]="{'margin-top': logoHeight ? ((logoHeight) + 'px') : ''}" [ngClass]="{'viewall-active': viewAllSelected}">
            <div class="container">
                <div class="m3u-tabs" *ngIf="showm3uContainer">
                    <ul>
                        <li [ngClass]="{active: m3uType === 'M3U_CHANNEL'}" (click)="selectM3uType('M3U_CHANNEL')">Channels</li>
                        <li [ngClass]="{active: m3uType === 'M3U_MOVIES'}" (click)="selectM3uType('M3U_MOVIES')">Movies</li>
                        <li [ngClass]="{active: m3uType === 'M3U_SERIES'}" (click)="selectM3uType('M3U_SERIES')">Series</li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="showm3uContainer && m3uType !== 'M3U_CHANNEL'">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="d-flex align-items-center">
                            <p-dropdown  [options]="m3uCategory" *ngIf="selectedM3uCategoryNameFromDropdown" (onChange)="selectCategory($event)" [(ngModel)]="selectedM3uCategory" optionLabel="Category" [filter]="true" filterBy="Category" [showClear]="true" placeholder="Select a Category">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2" *ngIf="selectedM3uCategory">
                                        <div>{{ selectedM3uCategory.Category }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div class="d-flex justify-content-between">
                                            <div>{{ item.Category }}</div>
                                            <div>({{ item.Count }})</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="app-search app-search-big mr-20 align-bar">
                                <input type="text" placeholder="Search" class="form-control " (keyup)="searchData($event)" #searchInput>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="m3u-data" *ngFor="let item of m3uData">
                            <div class=" title-wrapper d-flex justify-content-between align-items-center">
                                <h1 class="cagtagory-name">{{item.Category}}</h1>
                                <p class="viewAll" *ngIf="!showViewAll && item.Playlist.length>4" (click)="getM3uDataByCatagory(item.Category)">View all</p>
                                <p class="viewAll" *ngIf="showViewAll && viewAllSelected" (click)="goBackView()">Go Back</p>
                            </div>
                            <div class="playlistdata-wrapper" [ngClass]="{'wrap-data': showViewAll}">
                                <div class="playlistdata" *ngFor="let data of item.Playlist">
                                    <div class="d-flex flex-column">
                                        <div class="card" (click)="goToItemView(data)">
                                            <span class="fav-icon" *ngIf="isLoggedIn && data.isFavourite === 'true'">
                                                <i class="fa fa-heart" aria-hidden="true"></i>
                                            </span>
                                            <img class="item-logo" style="opacity:0" onload="style.opacity=1" [src]="data.tvgLogo" alt="" (error)="handleMissingImage($event)">
                                        </div>
                                        <p class="data-name">{{data.channelName}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" *ngIf="showNotFound">
                        <p>No result found</p>
                    </div>
                    <div class="no-data" style="text-align: center;line-height: 1.4;" *ngIf="showErrorMessage">
                        <p style="color: red;">Oops! This URL is invalid. Please use correct URL and try again.</p>
                    </div>
                </div>
            </div>
            <div *ngIf="m3uType === 'M3U_CHANNEL'">
                <app-m3uchannel [m3uData]="data" (scrolledToBottom)="onScrollEnd()"></app-m3uchannel>
            </div>
        </div>
    </div>
    <div class="pagintion m3u-pagination" *ngIf="showViewAll && viewAllSelected">
        <p-paginator class="custom_paginator" [rows]="rowCount" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} - {last} of {{totalElements}}"
            [rowsPerPageOptions]="[25,50,100,200]" (onPageChange)="loadMediaItem($event)"
            [totalRecords]="totalElements" pageLinkSize="3"></p-paginator>
    </div>
</div>
<p-dialog *ngIf="paymentModal" [closable]="false" header="" [(visible)]="paymentModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <div class="paid-info" *ngIf="currentVedioIsPaid">
            <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                class="watch-now mb-20">Get Access Now</button>
            <button *ngIf="isOneTimePurchase"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                class="watch-now mb-20" style="margin-left: 10px;">Pay Per View ${{itemPrice}}</button>
            <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                    (click)="signInOnMedia()">sign up
                </span>to continue</p>
        </div>

        <div class="paid-info" *ngIf="loginRequired">
            <p class="vedio-name mb-20">Please create a free account to watch the content</p>
            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
            <p class="vedio-name">or <span style="cursor: pointer;text-decoration: underline;" (click)="signInOnMedia()">Sign in </span>to
                continue</p>
        </div>
    </div>
    <p-footer class="signout-footer">
        <!-- <button type="button" class="btn btn-white" style="margin: 0 10px;" (click)="logOut()">Yes</button> -->
        <button type="button" class="btn btn-white" (click)="paymentModal= false">Cancel</button>
    </p-footer>
</p-dialog>