import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaService } from 'src/app/services/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
declare var videojs: any; // Declare external libraries

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    controls: boolean;
    from: any;
    sources: {
      src: string;
    }[];
  };
  @Input() pauseTime: number;
  @Input() currentVedio: any;
  @Input() playerValidation: boolean;
  @Input() poster: any;
  @Input() isVastTag: boolean;
  @Input() autoPlayChange: boolean;
  @Input() vastTag: string;
  @Input() mediaId: string;
  @Input() itemType: string;
  @Input() isVideoPause: boolean;
  @Input() showAds: boolean = false;
  @Input() enableAds: boolean = false;

  @Output() completeVideoAction: EventEmitter<any>;
  @Output() playedAction: EventEmitter<any>;
  @Output() languageChangedAction: EventEmitter<any>;
  @Output() resolutionChangeAction: EventEmitter<any>;
  @Output() playInitiation: EventEmitter<any>;
  @Output() pauseInitiation: EventEmitter<any>;
  @Output() dataUsageAction: EventEmitter<any>;

  prerollPlayed = false;
  midrollPlayed = false;
  postrollPlayed = false;
  player: any;
  adOptions: {
    adTagUrl: string;
  };
  currentStart = 0;
  currentEnd = 0;
  vastTagEnabled = true;

  // VAST Ad Tags
  vastTagPreroll = "https://ads.tenband.tv/ad.php?do=vast&zid=123843&oid=28740&wd=-1&ht=-1&vastver=3&cb=1723080222";
  vastTagPostroll = 'https://your_postroll_ad_url'; // Set your postroll ad URL
  lastAdPlayed: string = ''; // Track the last ad played

  constructor(
    private _SharedService: SharedService,
    private _MediaService: MediaService
  ) {
    this.currentVedio = { src: '' };
    this.playerValidation = false;
    this.pauseTime = 0;
    this.completeVideoAction = new EventEmitter<any>();
    this.playedAction = new EventEmitter<any>();
    this.languageChangedAction = new EventEmitter<any>();
    this.resolutionChangeAction = new EventEmitter<any>();
    this.playInitiation = new EventEmitter<any>();
    this.pauseInitiation = new EventEmitter<any>();
    this.dataUsageAction = new EventEmitter<any>();
    this.adOptions = {
      adTagUrl: this.vastTag,
    };
    this.isVastTag = false;
    this.mediaId = '';
    this.vastTagPreroll = this.vastTag
  }

  ngOnInit(): void {
    this.player = videojs(this.target.nativeElement, {
      ...this.options,
      autoplay: false, // Disable autoplay to control when video starts
      controlBar: {
        pictureInPictureToggle: false,
      },
    });
    this.player.aspectRatio('16:9');
    this.player.responsive(true);

    this.player.poster(this.poster);
    this.player.controls(this.options.controls);

    // Ensure ads are initialized on component load
    console.log(this.enableAds , "enableAds")
    if (this.enableAds) {
      this.initializeAds();
    } else {
      this.player.src(this.options.sources[0]);
      if (this.options.autoplay && !this.isVideoPause) {
        console.log("Autoplay without ads, starting video.");
        this.player.play();
      }
    }

    this.pauseTime && this.player.currentTime(this.pauseTime);
    this.player.on('error', (error: any) => {
      this._SharedService.updateLoader(true);
      this.resolutionChangeAction.emit(error);
      this._SharedService.updateLoader(false);
      // console.log(error);
    });
    this._MediaService.getVideoPlayerStatus().subscribe((data) => {
      if (!data) {
        this.player.pause();
      }
    });

    let loginUserDetail = localStorage.getItem('loggedInUser')
    if(loginUserDetail){
      this.vastTagEnabled = loginUserDetail
       ? JSON.parse(loginUserDetail).userDetails.vastTagEnabled
       : '';
     }
    
  }

  ngOnChanges(): void {
    console.log("ngOnChanges", this.player)
    if (this.player) {
      this.player.poster(this.poster);
      this.player.controls(this.options.controls);
      if (!this.showAds && !this.enableAds) {
        this.player.src(this.options.sources[0]);
      }
      this.pauseTime && this.player.currentTime(this.pauseTime);

      if (!this.isVideoPause && !this.showAds && this.enableAds) {
        console.log("!this.isVideoPause && !this.showAds")
        this.player.pause();
        if (this.player.activePlugins_ && this.player.activePlugins_.ads && this.player.activePlugins_.ima) {
          // console.log("!this.isVideoPause && !this.showAds", this.player.ima.getAdsManager(this.target.nativeElement))
          this.prerollPlayed = false
          this.midrollPlayed = false
          this.postrollPlayed = false
          // this.player.ima.getAdsManager(this.target.nativeElement).stop()
          this.player.ima.changeAdTag(this.vastTag);
          this.player.ima.requestAds();
        }else{
      this.initializeAds();

        }
      }
      if (!this.isVideoPause && !this.showAds && !this.enableAds) {
        console.log("!this.isVideoPause && !this.showAds && !this.player.ads.isAdPlaying()")
        this.player.play();
      }
      if (this.options.autoplay && !this.showAds) {
        console.log("this.options.autoplay && !this.showAds && !this.player.ads.isAdPlaying()")
        this.player.play();
      }
      if (this.showAds) {
        console.log("!this.isVideoPause && !this.showAds pause")

        this.player.pause();
      }
    }
  }

  initializeAds() {
    if (this.enableAds) {
      console.log(this.vastTag,"IN IMA VAST TAG PRE ROLL")
      // Set up IMA plugin with the ad tag and other options
      this.player.ima({
        adTagUrl: this.vastTag, // Initial ad tag (preroll)
        showControlsForAds: true,
        debug: true,
        autoPlayAdBreaks: true, // Ensure ads autoplay
      });



      // Pause the video immediately on player ready
      this.player.ready(() => {
        this.player.pause(); // Pause the video until ads are handled
      });

      // Initialize the ad display container when ready
      this.player.ima.initializeAdDisplayContainer();

      // Request the preroll ad as soon as the ads system is ready
      this.player.on('adsready', () => {
        if (!this.prerollPlayed) {
          console.log('Requesting preroll ad');
          this.player.ima.requestAds(); // Request the preroll ad
          this.player.play(); // Pause the video while the ad is playing
          this.prerollPlayed = true;
          this.player.src(this.options.sources[0]);
          this.lastAdPlayed = 'preroll';
        }
      });

      // Ensure the video remains paused while the ad is playing
      this.player.on('adstart', () => {
        console.log('Ad started, pausing video.');
        this.player.pause(); // Pause the video while the ad is playing
      });

      // When the ad ends, resume video playback
      this.player.on('adend', () => {
        console.log('Ad ended, resuming video playback.');
        this.lastAdPlayed = '';
        // this.player.src(this.options.sources[0]); // Set video source after ad ends
        this.player.play(); // Resume video playback after the ad ends
      });

      // If there's an error in the ad, resume the video immediately
      this.player.on('aderror', () => {
        console.log('Ad loding error occurred, skipping ad player.');
        // this.player.src(this.options.sources[0]); // Set video source on ad error
        this.player.play(); // Play the video if there's an ad error
      });

      this.player.on('adserror', () => {
        console.log('Ad error occurred, skipping ad.');
        // this.player.src(this.options.sources[0]); // Set video source on ad error
        this.player.play(); // Play the video if there's an ad error
      });

      // Midroll ad logic: play a midroll ad after 5 minutes
      this.player.on('timeupdate', () => {
        const currentTime = this.player.currentTime();
        if (currentTime >= 5 * 60 && !this.midrollPlayed) {
          console.log('Requesting midroll ad');
          this.requestMidrollAd(); // Request midroll ad after 5 minutes
          this.midrollPlayed = true;
        }
      });

      // Postroll ad logic: play a postroll ad at the end of the video
      this.player.on('ended', () => {
        if (!this.postrollPlayed) {
          console.log('Requesting postroll ad');
          this.player.ima.requestAds({ adTagUrl: this.vastTagPostroll });
          this.postrollPlayed = true;
          this.lastAdPlayed = 'postroll';
        }
      });
    }
  }
  
  // requestMidrollAd() {
  //   if (this.lastAdPlayed !== 'midroll') {
  //     console.log('Requesting midroll ad');
  //     this.player.ima.requestAds(); // Request midroll ad
  //     this.lastAdPlayed = 'midroll'; // Track midroll as played
  //   }
  // }


  isAdPlaying() {
    return this.player && this.player.ima && typeof this.player.ima.isAdPlaying === 'function'
      ? this.player.ima.isAdPlaying()
      : false; // Return false if IMA is not available
  }

  requestMidrollAd() {
    if (this.lastAdPlayed !== 'midroll') {
      this.player.ima.requestAds();
      this.lastAdPlayed = 'midroll'; // Track last ad played
    }
  }

  handleError(event: any) {
    console.log(event);
  }

  playerAction(event: any) {
    this.playedAction.emit(event);
    if(this.player.textTracks().length>0){
      if(this.player.textTracks()[0].activeCues[0]?.value){
        let newStart = this.player.textTracks()[0].activeCues[0].value.start
        let newEnd = this.player.textTracks()[0].activeCues[0].value.end
        this.checkNewChunkLoaded(newStart, newEnd);
      } 
    }
  }

  playInitiate(event: any): void {
    console.log("Adasd")
    this.playInitiation.emit(event);
  }

  pauseInitate(event: any): void {
    this.pauseInitiation.emit(event);
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  checkNewChunkLoaded(newStart: number, newEnd: number): void {
    if (newStart !== this.currentStart || newEnd !== this.currentEnd) {
      this.currentStart = newStart;
      this.currentEnd = newEnd;
      this.dataUsageAction.emit({
        byteLength: this.player.textTracks()[0].activeCues[0].value.byteLength,
      });
    }
  }
}
