<div [ngClass]="[showafterLoad ? '' : 'showNone']" class="main-program dark"
    [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a','padding-top': logoHeight && !smallScreen ? ((0 + 60) + 'px') : (smallScreen ? '0px' : '')}">
    <div class="container d-flex justify-content-between">
        <div class="vedio-wrapper"
            [ngClass]="{'col-8': playlistPosition === true , 'width100': playlistPosition === false}">
            <div class="player" [ngClass]="{sidebarView: playlistPosition === true}">

                <ng-container *ngIf="isYoutubeUrl">
                    <youtube-player width="100%" height="650" [videoId]="youtubeID" (ready)="savePlayer($event)"
                        (change)="onStateChange($event)"></youtube-player>
                </ng-container>
                <ng-container
                    *ngIf="!isYoutubeUrl && !vedioType && (vedioIsNull || currentVedioIsPaid || isOneTimePurchase ||  loginRequired )">
                    <app-video-player [ngStyle]="{display : !showAds ? 'block' : 'none' }"
                        [ngClass]="{'paidItem': (vedioIsNull || currentVedioIsPaid || isOneTimePurchase ||  loginRequired )}"
                        (dataUsageAction)="sendDataUsageToServer($event)" [poster]="poster" [pauseTime]="pauseTime"
                        (playedAction)="updateVedioPlay($event)" [enableAds]="inPlayerAds"
                        [autoPlayChange]="autoPlayChange" (playInitiation)="played($event)"
                        (resolutionChangeAction)="resolutionChange($event)" (pauseInitiation)="paused($event)"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [itemType]="itemType" [isVideoPause]="isVideoPause"
                        [showAds]="showAds"
                        [options]="{controls: false, sources: [{ src: currentVedioIsPaid ? '' : videoJsPlayerUrl,type:currentVedioIsPaid ? '' : videoJsPlayerType }]}">
                    </app-video-player>
                    <app-video-ads-player *ngIf="showAds" (playInitiation)="playedAds($event)" [itemType]="itemType"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [midRollVastTagURL]="midRollVastTagURL"
                        [preRollData]="preRollData" [midRollData]="midRollData"
                        (playedAction)="updateAdsVedioPlay($event)" [mediaId]="nextMediaItemId" [adsType]="adsType"
                        [options]="{autoplay:true,controls: true,muted: false, sources: [{ src:  currentVedioIsPaid ? '' : videoJsPlayerUrl1,type:currentVedioIsPaid ? '' : videoJsPlayerType1 }]}">
                    </app-video-ads-player>
                </ng-container>
                <ng-container
                    *ngIf="!isYoutubeUrl && !vedioType && !(vedioIsNull || currentVedioIsPaid || isOneTimePurchase||  loginRequired)">
                    <app-video-player [ngStyle]="{display : !showAds ? 'block' : 'none' }"
                        (dataUsageAction)="sendDataUsageToServer($event)" [poster]="poster" [pauseTime]="pauseTime"
                        (playedAction)="updateVedioPlay($event)" [enableAds]="inPlayerAds"
                        [autoPlayChange]="autoPlayChange" (playInitiation)="played($event)"
                        (resolutionChangeAction)="resolutionChange($event)" (pauseInitiation)="paused($event)"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [itemType]="itemType" [isVideoPause]="isVideoPause"
                        [showAds]="showAds"
                        [options]="{controls: true, sources: [{ src: currentVedioIsPaid ? '' : videoJsPlayerUrl,type:currentVedioIsPaid ? '' : videoJsPlayerType }]}">
                    </app-video-player>
                    <app-video-ads-player *ngIf="showAds" (playInitiation)="playedAds($event)" [itemType]="itemType"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [midRollVastTagURL]="midRollVastTagURL"
                        [preRollData]="preRollData" [midRollData]="midRollData"
                        (playedAction)="updateAdsVedioPlay($event)" [mediaId]="nextMediaItemId" [adsType]="adsType"
                        [options]="{autoplay:true,controls: true,muted: false, sources: [{ src:  currentVedioIsPaid ? '' : videoJsPlayerUrl1,type:currentVedioIsPaid ? '' : videoJsPlayerType1 }]}">
                    </app-video-ads-player>
                </ng-container>
                <div class="paid-info" *ngIf="vedioIsNull">
                    <p class="vedio-name mb-20">{{currentVedioTitle}}</p>
                    <p class="vedio-name mb-20">Video is not available for this media</p>
                </div>
                <div class="paid-info" *ngIf="currentVedioIsPaid">
                    <p class="vedio-name mb-20">{{currentVedioTitle}}</p>
                    <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                        [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                        class="watch-now mb-20">Get Access Now</button>
                    <button *ngIf="isOneTimePurchase"
                        [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                        class="watch-now mb-20" style="margin-left: 10px;">Pay Per View ${{itemPrice.toFixed(2)}}</button>
                    <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                            (click)="signInOnMedia()">sign up
                        </span>to continue</p>
                </div>

                <div class="paid-info" *ngIf="loginRequired">
                    <p class="vedio-name mb-20">Please create a free account to watch this video</p>
                    <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                        (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
                    <p class="vedio-name">or <span style="cursor: pointer;text-decoration: underline;"
                            (click)="signInOnMedia()">Sign in </span>to
                        continue</p>
                </div>

                <div class="paid-info" *ngIf="notLiveMsg">
                    <p class="vedio-name mb-20">Stream has not started yet, You will be able to watch once someone goes
                        live</p>
                </div>

                <div class="liveClass" *ngIf="showLiveIndication">
                    <!-- <button type="button" class="btn btn-primary position-relative">
                        Alerts
                    </button> -->
                    <!-- <p class="position-relative">
                        Live <span
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2"><span
                                class="visually-hidden">Live</span></span>
                    </p> -->
                </div>

                <ng-container
                    *ngIf="!isYoutubeUrl && vedioType && (vedioIsNull || currentVedioIsPaid || isOneTimePurchase||  loginRequired)">
                    <app-video-player [ngStyle]="{display : !showAds ? 'block' : 'none' }"
                        (dataUsageAction)="sendDataUsageToServer($event)" [poster]="poster" [pauseTime]="pauseTime"
                        (playedAction)="updateVedioPlay($event)" [enableAds]="inPlayerAds"
                        [autoPlayChange]="autoPlayChange" (resolutionChangeAction)="resolutionChange($event)"
                        (playInitiation)="played($event)" (pauseInitiation)="paused($event)" [isVastTag]="isVastTag"
                        [vastTag]="vastTag" [itemType]="itemType" [isVideoPause]="isVideoPause" [showAds]="showAds"
                        [options]="{controls: false, sources: [{ src: currentVedioIsPaid ? '' : videoJsPlayerUrl,type:currentVedioIsPaid ? '' : videoJsPlayerType }]}">
                    </app-video-player>
                    <app-video-ads-player *ngIf="showAds" (playInitiation)="playedAds($event)" [itemType]="itemType"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [midRollVastTagURL]="midRollVastTagURL"
                        [preRollData]="preRollData" [midRollData]="midRollData"
                        (playedAction)="updateAdsVedioPlay($event)" [mediaId]="nextMediaItemId" [adsType]="adsType"
                        [options]="{autoplay:true,controls: true,muted: false, sources: [{ src:  currentVedioIsPaid ? '' : videoJsPlayerUrl1,type:currentVedioIsPaid ? '' : videoJsPlayerType1 }]}">
                    </app-video-ads-player>
                </ng-container>
                <ng-container
                    *ngIf="!isYoutubeUrl && vedioType && !(vedioIsNull || currentVedioIsPaid || isOneTimePurchase||  loginRequired)">
                    <app-video-player [ngStyle]="{display : !showAds ? 'block' : 'none' }"
                        (dataUsageAction)="sendDataUsageToServer($event)" [poster]="poster" [pauseTime]="pauseTime"
                        (playedAction)="updateVedioPlay($event)" [enableAds]="inPlayerAds"
                        (resolutionChangeAction)="resolutionChange($event)" (playInitiation)="played($event)"
                        (pauseInitiation)="paused($event)" [isVastTag]="isVastTag" [vastTag]="vastTag"
                        [itemType]="itemType" [isVideoPause]="isVideoPause" [showAds]="showAds"
                        [autoPlayChange]="autoPlayChange"
                        [options]="{controls: true, sources: [{ src: currentVedioIsPaid ? '' : videoJsPlayerUrl,type:currentVedioIsPaid ? '' : videoJsPlayerType }]}">
                    </app-video-player>
                    <app-video-ads-player *ngIf="showAds" (playInitiation)="playedAds($event)" [itemType]="itemType"
                        [isVastTag]="isVastTag" [vastTag]="vastTag" [midRollVastTagURL]="midRollVastTagURL"
                        [preRollData]="preRollData" [midRollData]="midRollData"
                        (playedAction)="updateAdsVedioPlay($event)" [mediaId]="nextMediaItemId" [adsType]="adsType"
                        [options]="{autoplay:true,controls: true,muted:false, sources: [{ src:  currentVedioIsPaid ? '' : videoJsPlayerUrl1,type:currentVedioIsPaid ? '' : videoJsPlayerType1 }]}">
                    </app-video-ads-player>
                </ng-container>
                <div class="nextVideoPlay-continer" *ngIf="showNextVideoPlay">
                    <div class="main-wrapper">
                        <img [src]="nxtVideoImg">
                        <p>Video will play in {{videoCountDecrease ? videoCountDecrease : '0'}} sec</p>
                    </div>
                </div>

            </div>

            <div class="player-detail-wrapper">
                <div class="row ">
                    <div [ngClass]="{'col-lg-8 col-sm-12': playlistPosition === true , 'col-lg-9 col-sm-12': playlistPosition === false}"
                        style="padding:0">
                        <form #updatefavorite="ngForm">
                            <div class="video-detail">
                                <h2 class="m-0">{{currentVedioTitle}}</h2>
                                <h3>{{subTitle}}</h3>
                                <div class="favAndshare-btn">
                                    <button class="full-screen" *ngIf="isLoggedIn" (click)="favButtonClick()"
                                        name="favorite"><i class="fa fa-heart" aria-hidden="true"
                                            [ngStyle]="{'color':isFavourites === true ? 'red' : '#fff' }"></i> <span
                                            *ngIf="!isFavourites">{{' Add to Favorites'}}</span> <span
                                            *ngIf="isFavourites">{{' In Favorites'}}</span></button>
                                    <!-- <button>Share</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                    <div [ngClass]="{'col-lg-4 col-sm-12': playlistPosition === true, 'col-lg-3 col-sm-12': playlistPosition === false}"
                        style="padding: 0;">
                        <div class="next-video-wrapper" *ngIf="nextVedioTitle">
                            <div class="next-vedio-header d-flex justify-content-between align-items-center">
                                <h4>Next video</h4>
                                <div class="autoplay-wrapper d-flex align-items-center">
                                    <div class="title">Autoplay</div>
                                    <!-- <div class="autoplay-btn"> -->
                                    <label class="switch" container="body">
                                        <input type="checkbox" [checked]="true"
                                            (ngModelChange)="updateAutoPlayStatus($event)" [(ngModel)]="autoPlay">
                                        <span class="slider round"></span>
                                    </label>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div class="next-video" (click)="nextVedio(nextVedioId,'next')">
                                <img [src]="nextVedioImage" class="video-img" alt="">
                                <div class="video-name">
                                    {{nextVedioTitle}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-side-vedio" [ngClass]="{'col-4': playlistPosition === true}" *ngIf="playlistPosition">
            <div>
                <!-- <div class="next-video-wrapper mb-20">
                    <h4>Next video</h4>
                    <div class="next-video" (click)="nextVedio(nextVedioId,'next')">
                        <img [src]="nextVedioImage" class="video-img" alt="">
                        <div class="video-name">
                            {{nextVedioTitle}}
                        </div>
                    </div>
                </div> -->

                <div class="related-video-wrapper">
                    <div class="" *ngIf="mediadata.length">
                        <div class="related-title">Related video</div>
                        <div class="related-video">
                            <div class="col-12 media-card" *ngFor="let item of mediadata">
                                <a class="main_poster">
                                    <span class="image-container" (click)="nextVedio(item.id,'related')"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.mediaImg ? item.mediaImg : ''" alt="" onload="style.opacity = 1"
                                            style="opacity: 0;" class="images">
                                        <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}
                                        </div>
                                    </span>
                                </a>
                                <a class="card-title title">
                                    {{item.title}}
                                </a>
                                <a class="card-title ">
                                    {{item.subTitle}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <section class="about-comment-wrapper" *ngIf="showAboutSection"
        [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a'}">
        <!-- <ul class="menus container">
                <li (click)="showabout()" [ngClass]="{active: showAbout}">About</li>
            </ul> -->
        <div class="about-comment-container">
            <div class="col-12 d-flex container">
                <div class="col-sm-12 col-lg-8">
                    <section class="about-section detail-section" style="margin-left: 12px;" *ngIf="showAbout">
                        <div class="title">About</div>
                        <div class="detail" [ngClass]="{'limitTextHeight': isReadMore}">{{currentVedioDesc}}</div>
                        <a class="readmore" (click)="showText()"
                            *ngIf="currentVedioDesc ? currentVedioDesc.length > 170 : ''">
                            {{ isReadMore ? 'Read More': 'Read Less' }}
                        </a>
                    </section>
                    <!-- <section class="comment-section detail-section" *ngIf="showComment">
                            <div class="title">No Comments yet</div>
                            <div class="detail">
                                you don't have access to comments
                            </div>
                        </section> -->
                </div>
                <div class="col-4 d-none">
                    <!-- <div class="catagory">
                            <div class="title">Catagories</div>
                            <div class="catagories-list">
                                <ul>
                                    <li>Free view</li>
                                    <li>Religion</li>
                                    <li>Social Justice</li>
                                    <li>New Release</li>
                                </ul>
                            </div>
                        </div> -->
                </div>
            </div>
        </div>
    </section>
    <section class="next-video-mobile m-3 mb-5">
        <div class="player-detail-wrapper">
            <div class="row ">
                <div [ngClass]="{'col-lg-8 col-sm-12': playlistPosition === true , 'col-lg-6 col-sm-12': playlistPosition === false}"
                    style="padding:0">
                    <form #updatefavorite="ngForm">
                        <div class="video-detail">
                            <div class="favAndshare-btn">
                                <button *ngIf="isLoggedIn" (click)="favButtonClick()" name="favorite"><i
                                        class="fa fa-heart" aria-hidden="true"
                                        [ngStyle]="{'color':isFavourites === true ? 'red' : '#fff' }"></i>
                                    <span *ngIf="!isFavourites">{{' Add to Favorites'}}</span> <span
                                        *ngIf="isFavourites">{{' In Favorites'}}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div [ngClass]="{'col-lg-4 col-sm-12': playlistPosition === true, 'col-lg-6 col-sm-12': playlistPosition === false}"
                    style="padding: 0;">
                    <div class="next-video-wrapper" *ngIf="nextVedioTitle">
                        <div class="next-vedio-header d-flex justify-content-between align-items-center">
                            <h4>Next video</h4>
                            <div class="autoplay-wrapper d-flex align-items-center">
                                <div class="title">Autoplay</div>
                                <!-- <div class="autoplay-btn"> -->
                                <label class="switch" container="body">
                                    <input type="checkbox" [checked]="true"
                                        (ngModelChange)="updateAutoPlayStatus($event)" [(ngModel)]="autoPlay">
                                    <span class="slider round"></span>
                                </label>
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="next-video" (click)="nextVedio(nextVedioId,'next')">
                            <img [src]="nextVedioImage" class="video-img" alt="">
                            <div class="video-name">
                                {{nextVedioTitle}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="related-video-wrapper" *ngIf="!playlistPosition">
        <div class="container catalog" *ngIf="mediadata.length>0">
            <div class="related-title">Related video</div>
            <div class="related-video below-veiw">
                <div class="media-card" *ngFor="let item of mediadata">
                    <a class="main_poster">
                        <span class="image-container" (click)="nextVedio(item.id,'related')"
                            [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                            <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                [src]="item.mediaImg ? item.mediaImg : ''" alt="" onload="style.opacity = 1"
                                style="opacity: 0;" class="images">
                            <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}</div>
                        </span>
                    </a>
                    <a class="card-title title mt-2">
                        {{item.title}}
                    </a>
                    <a class="card-title ">
                        {{item.subTitle}}
                    </a>
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer *ngIf="showafterLoad"></app-footer>